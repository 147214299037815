@tailwind   base;
@tailwind   components;
@tailwind   utilities;

   
body {
    margin-top: 4rem;
    background-color: #1a1a1a;
    color: #e0e0e0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.post {
    margin-bottom: 2rem;
}

.social-icon {
    border-width: 0.2rem;
    color: #faf8f8;
    margin-right: 0.4rem;
    margin-left: 0.7rem;
}

.social-icon:hover {
    background-image: linear-gradient(45deg, rgba(0, 255, 255, 0.7), rgba(255, 0, 255, 0.7));
    background-color: transparent;
}
